export const HEADERS_GUARANTEE_ACCESSORIES = [
  {
    text: "STT",
    align: "start",
    sortable: false,
    value: "id"
  },
  {
    text: "Tên sản phẩm",
    align: "start",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Serials",
    align: "start",
    sortable: false,
    value: "serials"
  },
  {
    text: "Số lượng",
    align: "start",
    sortable: false,
    value: "quantity"
  },
  {
    text: "",
    align: "center",
    sortable: false,
    value: "action"
  }
];

export const HEADERS_GUARANTEE_ACCESSORIES_FULL = [
  {
    text: "STT",
    align: "start",
    sortable: false,
    value: "id"
  },
  {
    text: "Tên sản phẩm",
    align: "start",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Serials",
    align: "start",
    sortable: false,
    value: "serials"
  },
  {
    text: "Số lượng",
    align: "start",
    sortable: false,
    value: "quantity"
  },
  {
    text: "Giá vốn TB",
    align: "start",
    sortable: false,
    value: "created_price"
  },
  {
    text: "Tổng giá trị",
    align: "start",
    sortable: false,
    value: "total"
  },
  {
    text: "",
    align: "center",
    sortable: false,
    value: "action"
  }
];

export const HEADERS_LISTING_GUARANTEE_VOUCHER = [
  {
    text: "Mã phiếu",
    align: "start",
    sortable: false,
    value: "code"
  },
  {
    text: "Sản phẩm",
    align: "start",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Serial",
    align: "start",
    sortable: false,
    value: "serial_number"
  },
  {
    text: "Khách hàng",
    align: "start",
    sortable: false,
    value: "customer_name"
  },
  {
    text: "SĐT Khách hàng",
    align: "start",
    sortable: false,
    value: "customer_phone"
  },
  {
    text: "Tạo lúc",
    align: "start",
    sortable: true,
    value: "created_at"
  },
  {
    text: "Cập nhật lúc",
    align: "start",
    sortable: true,
    value: "updated_at"
  },
  {
    text: "Chi nhánh",
    align: "start",
    sortable: false,
    value: "branch_name"
  },
  {
    text: "Tạo bởi",
    align: "start",
    sortable: false,
    value: "created_by_user_name"
  },
  {
    text: "Phụ trách",
    align: "start",
    sortable: false,
    value: "assigned_to_user_name"
  },
  {
    text: "Đối tác sửa chữa",
    align: "start",
    sortable: false,
    value: "service_supplier"
  },
  {
    text: "Tổng chi phí",
    align: "start",
    sortable: false,
    value: "total_fee"
  },
  {
    text: "Khách phải trả",
    align: "start",
    sortable: false,
    value: "customer_fee"
  },
  {
    text: "Khách đã trả",
    align: "start",
    sortable: false,
    value: "customer_value"
  },
  {
    text: "Khách nợ",
    align: "start",
    sortable: false,
    value: "customer_remains"
  },
  {
    text: "Trạng thái",
    align: "start",
    sortable: false,
    value: "step"
  },
  {
    text: "Đã hủy",
    align: "start",
    sortable: false,
    value: "huy_status"
  }
];

export const HEADERS_REPAIR_ACCESSORIES = [
  {
    text: "STT",
    align: "start",
    sortable: false,
    value: "id"
  },
  {
    text: "Tên sản phẩm",
    align: "start",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Serials",
    align: "start",
    sortable: false,
    value: "serials"
  },
  {
    text: "Số lượng",
    align: "start",
    sortable: false,
    value: "quantity"
  },
  {
    text: "Đơn giá",
    align: "start",
    sortable: false,
    value: "unit_sold_price"
  },
  {
    text: "Chiết khấu",
    align: "start",
    sortable: false,
    value: "rebate_amount"
  },
  {
    text: "Giá cuốí",
    align: "start",
    sortable: false,
    value: "sold_price"
  },
  {
    text: "Thành tiền",
    align: "start",
    sortable: false,
    value: "total"
  },
  {
    text: "",
    align: "center",
    sortable: false,
    value: "action"
  }
];

export const CANCEL_STATUSES = {
  ALL: -1,
  NOT_CANCELED_YET: 0,
  CANCELLED: 1
};
