<template>
  <tp-modal name="modal-outer-serial" width="50%" max-width="840px">
    <v-card class="border-0">
      <div class="card px-5 py-3 align-center d-flex" height="56x">
        <v-toolbar-title class="font-weight-bold">
          Thêm mới Serial ngoài hệ thống
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-card>

    <!--        Start: actions-->
    <div
      class="v-window-item tab-item grey lighten-3 pa-5 v-window-item--active"
    >
      <v-card>
        <!--        Start: body -->
        <div class="card mt-5 modal-body-scroll">
          <div class="tab-item lighten-3 pa-5">
            <v-row class="px-5">
              <v-col cols="12" class="pb-3">
                <div class="font-weight-bold mb-2">Tên khách hàng:</div>
                <div class="d-flex align-center">
                  <v-autocomplete
                    v-model="newSerial.customer_id"
                    class="text-body-1"
                    clearable
                    dense
                    flat
                    hide-details
                    :items="customers"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ maxWidth: '332px' }"
                    no-data-text="Không có dữ liệu"
                    no-filter
                    placeholder="Tìm khách hàng"
                    prepend-inner-icon="mdi-magnify"
                    :search-input.sync="customerSearchKey"
                    small-chips
                    solo
                    outlined
                  >
                    <template v-slot:selection="{ item }">
                      <v-hover v-slot:default="{ hover }">
                        <div
                          class="text-truncate tp-btn--text rounded-xl px-3 py-1"
                          :class="hover ? 'cyan lighten-4' : 'cyan lighten-5'"
                          @click="openModalCustomer(item.id)"
                        >
                          {{ item.name }}
                        </div>
                      </v-hover>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content :title="data.item.name">
                        <v-list-item-title
                          class="font-weight-medium"
                          v-html="data.item.name"
                        ></v-list-item-title>
                        <div class="grey--text text--darken-2 text-subtitle-2">
                          <span v-if="data.item.phone">{{
                            data.item.phone | VMask("### ### ####")
                          }}</span>
                        </div>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-btn
                    class="cyan lighten-5 btn-add px-0 ml-3"
                    height="40px"
                    width="40px"
                    depressed
                    color="primary"
                    icon
                    @click="openModalCustomer()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" class="pb-3">
                <div class="font-weight-bold mb-2">Serial:</div>
                <v-text-field
                  v-model="newSerial.serial_number"
                  class="text-body-1"
                  dense
                  flat
                  hide-details
                  outlined
                  placeholder="Nhập Serial"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="px-5">
              <v-col cols="12" class="pb-3">
                <div class="font-weight-bold mb-2">Tên sản phẩm:</div>
                <v-text-field
                  v-model="newSerial.product_name"
                  class="text-body-1"
                  dense
                  flat
                  hide-details
                  outlined
                  placeholder="Nhập tên sản phẩm"
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-3">
                <div class="font-weight-bold mb-2">Cấu hình tóm tắt:</div>
                <v-textarea
                  v-model="newSerial.short_des"
                  class="text-body-1"
                  hide-details
                  outlined
                  placeholder="Nhập mô tả cấu hình tóm tắt"
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
      <v-btn
        class="rounded-lg mt-5"
        color="primary"
        depressed
        @click="createOuterSerial"
      >
        Lưu
      </v-btn>
    </div>
  </tp-modal>
</template>

<script>
export default {
  props: {
    guaranteeVoucherStatusRequest: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      customerSearchKey: null,
      newSerial: {}
    };
  },
  computed: {
    customers() {
      return this.$store.getters["CUSTOMER/customers"];
    }
  },
  watch: {
    customerSearchKey(val) {
      if (val && val.length > 0) {
        this.$store.dispatch("CUSTOMER/getCustomers", {
          cur_page: 1,
          per_page: 100,
          search: val
        });
      }
    }
  },
  created() {
    this.$store.dispatch("CUSTOMER/getCustomers", {
      cur_page: 1,
      per_page: 100,
      search: ""
    });
  },
  methods: {
    closeModal() {
      this.newSerial = {};
      this.$modal.hide({
        name: "modal-outer-serial"
      });
    },

    async createOuterSerial() {
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER/createOuterSerial",
        this.newSerial
      );
      if (
        this.guaranteeVoucherStatusRequest &&
        this.guaranteeVoucherStatusRequest.value === "success-createOuterSerial"
      ) {
        this.closeModal();
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Tạo thành công Serial ngoài hệ thống"
          }
        });
      }
      if (
        this.guaranteeVoucherStatusRequest &&
        this.guaranteeVoucherStatusRequest.value === "error-createOuterSerial"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Serial này đã tồn tại"
          }
        });
      }
    },

    async openModalCustomer(customerId) {
      if (customerId) {
        await this.$store.dispatch("CUSTOMER/getCustomerById", customerId);
      } else {
        await this.$store.dispatch("CUSTOMER/resetCustomer");
      }

      this.$modal.show({ name: "modal-customer" });
    }
  }
};
</script>

<style scoped lang="scss">
.border-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.v-card {
  box-shadow: none !important;
}
</style>
